import { captureException } from '@sentry/nextjs';
import { JSONContent } from '@tiptap/react';
import { orderBy } from 'lodash/fp';
import { useCallback } from 'react';
import {
  atom,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
  atomFamily,
  useRecoilCallback,
  useRecoilState,
} from 'recoil';

import { WaldoAPI } from '../../../lib/api';
import { localStorageEffect } from '../../utils/recoil';
import { selectedTeamState } from '../teams';
import {
  FindNotesParams,
  Note,
  CreateNotePayload,
  UpdateNotePayload,
  NoteExport,
  FetchOptions,
  FindByFindingIdParams,
  FindByFindingIdResponse,
} from './types';
import { TrackableEvent, TrackableTarget } from '../../../lib/trackable';
import { useTracking } from '../tracking';

class NoteAPI extends WaldoAPI {
  private baseUrl = '/v1/notes';

  async find({ q, teamId }: FindNotesParams): Promise<Note[]> {
    return this.request({
      method: 'GET',
      path: this.baseUrl,
      query: {
        q,
        teamId,
      },
    });
  }

  async findById(noteId: number): Promise<Note | undefined> {
    try {
      return await this.request({
        method: 'GET',
        path: `${this.baseUrl}/${noteId}`,
      });
    } catch (err) {
      return undefined;
    }
  }

  async findPersonal({ q }: { q?: string }): Promise<Note[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/personal`,
      query: {
        q,
      },
    });
  }

  async findByFindingId({
    findingId,
    q,
  }: FindByFindingIdParams): Promise<FindByFindingIdResponse> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/by_finding_id`,
      query: {
        findingId,
        q,
      },
    });
  }

  async create({ title, teamId, findingId }: CreateNotePayload): Promise<Note> {
    return this.request({
      method: 'POST',
      path: this.baseUrl,
      body: {
        teamId,
        findingId,
        title,
      },
    });
  }

  async update({
    noteId,
    title,
    teamId,
    isPublic,
    indexPage,
    hideSources,
    featureImageUrl,
  }: UpdateNotePayload): Promise<Note> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${noteId}`,
      body: {
        title,
        teamId,
        isPublic,
        indexPage,
        hideSources,
        featureImageUrl,
      },
    });
  }

  async delete(noteId: number): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${noteId}`,
    });
  }

  async deleteEmptyNote(noteId: number): Promise<Partial<Note>> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${noteId}/empty`,
    });
  }

  async pin(noteId: number): Promise<Note> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${noteId}/pin`,
    });
  }

  async unpin(noteId: number): Promise<Note> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${noteId}/pin`,
    });
  }

  async export({ noteId, ...xport }: NoteExport): Promise<NoteExport> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${noteId}/export`,
      body: {
        ...xport,
      },
    });
  }
}

const orderNotes = (notes: Note[]): Note[] =>
  orderBy(['pinned', 'updatedAt'], ['desc', 'desc'], notes);

export const noteApi = new NoteAPI();

// Find Notes
export const notesState = atom<Note[]>({
  key: 'notesState',
  default: selector({
    key: 'notesLoader',
    get: () => noteApi.find({}),
  }),
});

export const useNotes = (): Note[] => useRecoilValue(notesState);

export const useRefetchNotes = () =>
  useRecoilCallback(({ set }) => async () => {
    const notes = await noteApi.find({});
    set(notesState, notes);
  });

export const useSetNotes = (): SetterOrUpdater<Note[]> =>
  useSetRecoilState(notesState);

// Find Note
export const noteState = atomFamily({
  key: 'noteState',
  default: selectorFamily({
    key: 'noteLoader',
    get: (noteId?: number) => () =>
      noteId ? noteApi.findById(noteId) : undefined,
  }),
});

export const useNote = (noteId: number): Note | undefined =>
  useRecoilValue(noteState(noteId));

export const useSetNote = (noteId: number): SetterOrUpdater<Note | undefined> =>
  useSetRecoilState(noteState(noteId));

// Find Personal Notes
export const personalNotesState = atom<Note[]>({
  key: 'personalNoteState',
  default: selector({
    key: 'personalNoteLoader',
    get: () => noteApi.findPersonal({}),
  }),
});

export const usePersonalNotes = (options?: FetchOptions): Note[] => {
  if (options?.disabled) {
    return [];
  }
  return useRecoilValue(personalNotesState);
};

export const useSetPersonalNotes = (): SetterOrUpdater<Note[]> =>
  useSetRecoilState(personalNotesState);

// Find Notes By Team
export const notesByTeamState = atomFamily<Note[], FindNotesParams>({
  key: 'notesByTeamState',
  default: selectorFamily<Note[], FindNotesParams>({
    key: 'notesByTeamLoader',
    get:
      ({ teamId }: FindNotesParams) =>
      () =>
        teamId ? noteApi.find({ teamId }) : [],
  }),
});

export const useNotesByTeam = (
  { teamId }: FindNotesParams,
  options?: FetchOptions,
): Note[] => {
  if (options?.disabled) {
    return [];
  }
  return useRecoilValue(notesByTeamState({ teamId }));
};

export const useSetNotesByTeam = (teamId: number): SetterOrUpdater<Note[]> =>
  useSetRecoilState(notesByTeamState({ teamId }));

export const selectedNoteIdState = atom<number | null>({
  key: 'selectedNoteId',
  default: selector({
    key: 'selectedNoteIdLoader',
    get: async ({ get }) => {
      try {
        const selectedTeam = get(selectedTeamState);

        if (selectedTeam) {
          return (
            get(notesByTeamState({ teamId: selectedTeam.teamId }))[0]?.noteId ||
            null
          );
        }

        return get(personalNotesState)[0]?.noteId || null;
      } catch (e) {
        return null;
      }
    },
  }),
  effects: [localStorageEffect],
});

export const useSelectedNoteId = (): number | null =>
  useRecoilValue(selectedNoteIdState);

export const useSetSelectedNoteId = (): SetterOrUpdater<number | null> =>
  useSetRecoilState(selectedNoteIdState);

export const useSelectedNoteIdState = (): [
  number | null,
  SetterOrUpdater<number | null>,
] => useRecoilState(selectedNoteIdState);

const selectedNoteState = selector({
  key: 'selectedNote',
  get: ({ get }) => {
    const noteId = get(selectedNoteIdState);

    if (!noteId) {
      return undefined;
    }

    return get(noteState(noteId));
  },
});

export const useSelectedNote = () => useRecoilValue(selectedNoteState);

// Find Note By URL
export const useNotesContainingUrl = (): ((url: string) => Note[]) =>
  useRecoilCallback(
    ({ snapshot }) =>
      (url: string) => {
        const notes = snapshot.getLoadable(notesState).getValue();
        return notes.filter((note) =>
          note?.content?.content?.some(
            (data: JSONContent) =>
              data.type === 'finding' && data.attrs?.url === url,
          ),
        );
      },
    [],
  );

// Create Note
export const useCreateNote = (): ((
  payload: CreateNotePayload,
) => Promise<Note>) => {
  const trackEvent = useTracking();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (payload: CreateNotePayload) => {
        const release = snapshot.retain();
        const personalNotes = await snapshot.getPromise(personalNotesState);
        const note = await noteApi.create(payload);
        const notes = await snapshot.getPromise(notesState);

        set(notesState, orderNotes([note, ...notes]));
        set(noteState(note.noteId), note);

        if (note.teamId) {
          const teamNotes = await snapshot.getPromise(
            notesByTeamState({ teamId: note.teamId }),
          );
          set(
            notesByTeamState({ teamId: note.teamId }),
            orderNotes([note, ...teamNotes]),
          );
        } else {
          set(personalNotesState, orderNotes([note, ...personalNotes]));
        }

        set(selectedNoteIdState, note.noteId);

        release();

        trackEvent(TrackableEvent.ACTION, {
          target: TrackableTarget.CREATE_NOTE,
        });
        return note;
      },
  );
};

// Update Note
export const useUpdateNote = (): ((
  payload: UpdateNotePayload,
) => Promise<Note>) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (payload: UpdateNotePayload) => {
        const updatedNote = {
          ...(await noteApi.update(payload)),
          updatedAt: new Date().toISOString(),
        };

        const updater = (notes: Note[]): Note[] =>
          orderNotes(
            notes.map((n) => (payload.noteId === n.noteId ? updatedNote : n)),
          );

        const allNotes = await snapshot.getPromise(notesState);
        const note = await snapshot.getPromise(noteState(updatedNote.noteId));
        const teamNotes = await snapshot.getPromise(
          notesByTeamState({ teamId: updatedNote.teamId }),
        );
        const personalNotes = await snapshot.getPromise(personalNotesState);

        set(notesState, updater(allNotes));
        set(selectedNoteIdState, updatedNote.noteId);
        set(noteState(payload.noteId), { ...note, ...updatedNote });

        if (updatedNote.teamId) {
          set(
            notesByTeamState({ teamId: updatedNote.teamId }),
            updater(teamNotes),
          );
        } else {
          set(personalNotesState, updater(personalNotes));
        }

        return updatedNote;
      },
  );

// Find Notes By Finding Id
export const notesByFindingIdState = atomFamily({
  key: 'noteByFindingIdState',
  default: selectorFamily({
    key: 'noteByFindingIdLoader',
    get:
      ({ findingId }: FindByFindingIdParams) =>
      async () =>
        noteApi.findByFindingId({ findingId }),
  }),
});

export const useNotesByFindingId = ({
  findingId,
}: FindByFindingIdParams): FindByFindingIdResponse =>
  useRecoilValue(notesByFindingIdState({ findingId }));

export const useSetNotesByFindingId = ({
  findingId,
}: FindByFindingIdParams): SetterOrUpdater<FindByFindingIdResponse> =>
  useSetRecoilState(notesByFindingIdState({ findingId }));

// Delete if note is empty
export const useDeleteEmptyNote = (): ((noteId: number) => Promise<void>) =>
  useRecoilCallback(({ set, snapshot }) => async (noteId: number) => {
    const release = snapshot.retain();
    const note = await snapshot.getPromise(noteState(noteId));

    if (!note) {
      release();
      return;
    }

    try {
      const deletedNote = await noteApi.deleteEmptyNote(noteId);

      if (deletedNote) {
        const updater = (notes: Note[]): Note[] =>
          notes.filter((n) => noteId !== n.noteId);

        const allNotes = await snapshot.getPromise(notesState);
        const teamNotes = await snapshot.getPromise(
          notesByTeamState({ teamId: note.teamId }),
        );
        const personalNotes = await snapshot.getPromise(personalNotesState);

        set(notesState, updater(allNotes));
        set(noteState(noteId), undefined);

        if (note.teamId) {
          set(notesByTeamState({ teamId: note.teamId }), updater(teamNotes));
        } else {
          set(personalNotesState, updater(personalNotes));
        }
      }
    } catch (err) {
      captureException(err);
      throw err;
    }

    release();
  });

// Delete Note
export const useDeleteNote = (): ((noteId: number) => Promise<void>) => {
  const trackEvent = useTracking();

  return useCallback(async (noteId: number) => {
    try {
      await noteApi.delete(noteId);
    } catch (err) {
      captureException(err);
      throw err;
    }

    trackEvent(TrackableEvent.ACTION, {
      target: TrackableTarget.DELETE_NOTE,
    });
  }, []);
};

// Pin Note
export const usePinNote = (): ((
  noteId: number,
) => Promise<Note | undefined>) => {
  const trackEvent = useTracking();

  return useRecoilCallback(({ set, snapshot }) => async (noteId: number) => {
    const release = snapshot.retain();
    const note = await snapshot.getPromise(noteState(noteId));

    if (!note) {
      release();
      return undefined;
    }

    const updatedNote = await noteApi.pin(noteId);

    const updater = (notes: Note[]): Note[] =>
      orderNotes(notes.map((n) => (noteId === n.noteId ? updatedNote : n)));

    const allNotes = await snapshot.getPromise(notesState);
    const teamNotes = await snapshot.getPromise(
      notesByTeamState({ teamId: note.teamId }),
    );
    const personalNotes = await snapshot.getPromise(personalNotesState);
    set(noteState(noteId), updatedNote);

    set(notesState, updater(allNotes));
    if (updatedNote.teamId) {
      set(notesByTeamState({ teamId: updatedNote.teamId }), updater(teamNotes));
    } else {
      set(personalNotesState, updater(personalNotes));
    }

    release();

    trackEvent(TrackableEvent.ACTION, {
      target: TrackableTarget.PIN_NOTE,
    });
    return updatedNote;
  });
};

// Unpin Note
export const useUnpinNote = (): ((
  noteId: number,
) => Promise<Note | undefined>) => {
  const trackEvent = useTracking();

  return useRecoilCallback(({ set, snapshot }) => async (noteId: number) => {
    const release = snapshot.retain();
    const note = await snapshot.getPromise(noteState(noteId));

    if (!note) {
      release();
      return undefined;
    }

    const updatedNote = await noteApi.unpin(noteId);

    const updater = (notes: Note[]): Note[] =>
      orderNotes(notes.map((n) => (noteId === n.noteId ? updatedNote : n)));

    const allNotes = await snapshot.getPromise(notesState);
    const teamNotes = await snapshot.getPromise(
      notesByTeamState({ teamId: note.teamId }),
    );
    const personalNotes = await snapshot.getPromise(personalNotesState);

    set(notesState, updater(allNotes));
    set(noteState(noteId), updatedNote);

    if (updatedNote.teamId) {
      set(notesByTeamState({ teamId: updatedNote.teamId }), updater(teamNotes));
    } else {
      set(personalNotesState, updater(personalNotes));
    }

    release();

    trackEvent(TrackableEvent.ACTION, {
      target: TrackableTarget.UNPIN_NOTE,
    });
    return updatedNote;
  });
};

export const useExportNote = (): ((
  xport: NoteExport,
) => Promise<NoteExport>) => {
  const trackEvent = useTracking();

  return useRecoilCallback(({ set, snapshot }) => async (xport: NoteExport) => {
    const release = snapshot.retain();

    const response = await noteApi.export(xport);
    const note = await snapshot.getPromise(noteState(xport.noteId));

    set(selectedNoteIdState, xport.noteId);

    if (note) {
      set(noteState(xport.noteId), {
        ...note,
        export: {
          googleId: xport.googleId,
        },
      });
    }

    release();

    trackEvent(TrackableEvent.ACTION, {
      target: TrackableTarget.EXPORT_NOTE_TO_GOOGLE_DOCS,
    });
    return response;
  });
};

const isNoteEmptyState = selectorFamily<boolean, Partial<Note> | undefined>({
  key: 'isNoteEmptyState',
  get: (note: Partial<Note> | undefined) => () =>
    !!(!note?.title && !note?.featureImageUrl),
});

export const useIsNoteEmpty = (note?: Partial<Note>): boolean =>
  useRecoilValue(isNoteEmptyState(note));
export const useUpdateNoteTimestamp = () =>
  useRecoilCallback(({ set, snapshot }) => async () => {
    const release = snapshot.retain();
    const selectedNote = await snapshot.getPromise(selectedNoteState);

    const updatedNote: Note | undefined = selectedNote
      ? {
          ...selectedNote,
          updatedAt: new Date().toISOString(),
        }
      : undefined;

    if (updatedNote?.noteId) {
      const updater = (notes: Note[]) =>
        orderNotes(
          notes.map((n) => (updatedNote.noteId === n.noteId ? updatedNote : n)),
        );

      const allNotes = await snapshot.getPromise(notesState);

      set(noteState(updatedNote.noteId), (note) =>
        note ? updatedNote : undefined,
      );
      set(notesState, () => updater(allNotes));

      if (updatedNote.teamId) {
        const teamNotes = await snapshot.getPromise(
          notesByTeamState({ teamId: selectedNote?.teamId }),
        );
        set(notesByTeamState({ teamId: updatedNote.teamId }), () =>
          updater(teamNotes),
        );
      } else {
        const personalNotes = await snapshot.getPromise(personalNotesState);
        set(personalNotesState, () => updater(personalNotes));
      }
    }
    release();
  });
